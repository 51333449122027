import { action, observable } from 'mobx';
import type { BaseData, ValueAndLabelData } from 'egenie-utils';
import { request } from 'egenie-common';
import type { CirculationData, CommonAreaItem, StorageAreaItem } from './types';
import { ENUM_MODULE_TYPE } from '../constants';

function bossTenantGetWarehouse(): Promise<ValueAndLabelData> {
  return request<Array<{ warehouse_id: number; warehouse_name: string; }>>({ url: '/api/cloud/baseinfo/rest/warehouse/cloud/listAllTenantWarehouse' })
    .then((info) => {
      if (Array.isArray(info)) {
        return info.map((item) => ({
          value: `${item.warehouse_id}`,
          label: item.warehouse_name,
          ...item,
        }));
      } else {
        return [];
      }
    });
}

export class Store {
  constructor() {
    this.handleRefresh();
    this.getWarehouseData();
  }

  @observable public lastUpdate = Date.now();

  @observable public arrivalData: CommonAreaItem[] = [];

  @observable public deliverData: CommonAreaItem[] = [];

  @observable public expressCollectionData: CommonAreaItem[] = [];

  @observable public secondarySortingData: CommonAreaItem[] = [];

  @observable public plusServiceData: CommonAreaItem[] = [];

  @observable public refundVendorData: CommonAreaItem[] = [];

  @observable public afterSaleData: CommonAreaItem[] = [];

  @action public handleRefresh = () => {
    if (this.warehouse) {
      this.lastUpdate = Date.now();
      this.getCirculationData();
      this.getStorageAreaData();

      [
        {
          value: ENUM_MODULE_TYPE.arrival.value,
          key: 'arrivalData',
        },
        {
          value: ENUM_MODULE_TYPE.deliver.value,
          key: 'deliverData',
        },
        {
          value: ENUM_MODULE_TYPE.expressCollection.value,
          key: 'expressCollectionData',
        },
        {
          value: ENUM_MODULE_TYPE.secondarySorting.value,
          key: 'secondarySortingData',
        },
        {
          value: ENUM_MODULE_TYPE.plusService.value,
          key: 'plusServiceData',
        },
        {
          value: ENUM_MODULE_TYPE.refundVendor.value,
          key: 'refundVendorData',
        },
        {
          value: ENUM_MODULE_TYPE.afterSale.value,
          key: 'afterSaleData',
        },
      ].forEach((item) => {
        request<BaseData<CommonAreaItem[]>>({
          url: '/api/cloud/wms/report/module/statistics/get/area/module/info',
          method: 'post',
          data: {
            moduleType: item.value,
            warehouseId: this.warehouse,
          },
        })
          .then((info) => this[item.key] = (Array.isArray(info.data) ? info.data : []));
      });
    }
  };

  @observable public storageAreaData: StorageAreaItem[] = [];

  @action public getStorageAreaData = () => {
    request<BaseData<StorageAreaItem[]>>({ url: `/api/cloud/wms/report/module/statistics/get/storage/area/module/info/${this.warehouse}` })
      .then((info) => this.storageAreaData = info.data || []);
  };

  @observable public circulationData: CirculationData = {};

  @action public getCirculationData = () => {
    request<BaseData<CirculationData>>({ url: `/api/cloud/wms/report/module/statistics/get/circulation/info/${this.warehouse}` })
      .then((info) => this.circulationData = info.data || {});
  };

  @observable public warehouse: string = undefined;

  @observable public warehouseData: ValueAndLabelData = [];

  @action public getWarehouseData = () => {
    bossTenantGetWarehouse()
      .then((warehouseData) => {
        this.warehouseData = warehouseData;
        this.warehouse = warehouseData?.[0]?.value;
        this.handleRefresh();
      });
  };

  @action public handleWarehouseChange = (warehouse: string): void => {
    this.warehouse = warehouse;
    this.handleRefresh();
  };
}
