import React from 'react';
import styles from './storageAreaContainer.less';
import { Col, Row, Space, Tooltip, Typography } from 'antd';
import { thousandthSeparator } from 'egenie-common';
import { observer } from 'mobx-react';
import type { StorageAreaItem } from '../types';
import classnames from 'classnames';
import { nanoid } from 'nanoid';
import type { Store } from '../store';

const ENUM_STORAGE_AREA_STATUS = {
  empty: {
    value: 0,
    label: '空',
    color: '#02C190',
    bgImg: require('../images/状态-空.png'),
  },
  used: {
    value: 1,
    label: '工作中',
    color: '#34b1fe',
    bgImg: require('../images/状态-已使用.png'),
  },
  full: {
    value: 2,
    label: '满',
    color: '#fc4c7d',
    bgImg: require('../images/状态-满.png'),
  },
};

interface StorageAreaViewItemProps {
  data: StorageAreaItem[];
  width: number;
  height: number;
  left: number;
  top: number;
  className?: string;
  style?: React.CSSProperties;
}

const StorageAreaViewItem: React.FC<StorageAreaViewItemProps> = observer(({
  data,
  width,
  height,
  left,
  top,
  className = '',
  style = {},
}) => {
  return (
    <div
      className={classnames(className, styles.storageAreaContainer)}
      style={{
        width,
        height,
        left,
        top,
        ...style,
      }}
    >
      <Row
        gutter={[
          24,
          24,
        ]}
      >
        {
          data.map((storageItem) => {
            return (
              <Col
                key={nanoid()}
                span={12}
              >
                <div>
                  <div className={styles.headerContainer}>
                    <Typography.Title
                      ellipsis
                      level={3}
                      title={storageItem.warehouseAreaName}
                    >
                      {storageItem.warehouseAreaName}
                    </Typography.Title>
                  </div>
                  <Row
                    gutter={[
                      24,
                      24,
                    ]}
                  >
                    {
                      (storageItem.stocks || []).map((item) => {
                        const statusItem = Object.values(ENUM_STORAGE_AREA_STATUS)
                          .find((val) => val.value === item.status) || ENUM_STORAGE_AREA_STATUS.used;

                        return (
                          <Col
                            key={nanoid()}
                            span={8}
                          >
                            <Tooltip
                              arrowPointAtCenter
                              color="#014775"
                              overlayStyle={{ maxWidth: 500 }}
                              title={(
                                <Space
                                  direction="vertical"
                                  size={4}
                                >
                                  <div className={styles.commonDescription}>
                                    <Typography.Title
                                      ellipsis
                                      level={3}
                                    >
                                      {item.warehouseShelfName}
                                    </Typography.Title>
                                  </div>
                                  <div className={styles.commonDescription}>
                                    <Typography.Title
                                      ellipsis
                                      level={3}
                                    >
                                      已存储(件): &nbsp;
                                      {thousandthSeparator(item.stockNum)}
                                    </Typography.Title>
                                  </div>
                                  <span
                                    className={styles.statusContainer}
                                    style={{ backgroundColor: statusItem.color }}
                                  >
                                    {statusItem.label}
                                  </span>
                                </Space>
                              )}
                            >
                              <div
                                className={styles.commonItem}
                                style={{ backgroundImage: `url(${statusItem.bgImg})` }}
                              >
                                <Typography.Text ellipsis>
                                  {item.warehouseShelfName}
                                </Typography.Text>
                              </div>
                            </Tooltip>
                          </Col>
                        );
                      })
                    }
                  </Row>
                </div>
              </Col>
            );
          })
        }
      </Row>
    </div>
  );
});

export const StorageAreaContainer: React.FC<{ store: Store; }> = observer(({ store: { storageAreaData }}) => {
  return (
    <StorageAreaViewItem
      data={storageAreaData}
      height={620}
      left={550}
      top={420}
      width={510}
    />
  );
});

