import { getStaticResourceUrl } from 'egenie-common';
import React from 'react';
import { FullScreenContainer } from './fullScreenContainer';
import { ProcessContainer } from './processContainer';
import { ScreenContainer } from './screenContainer';
import { StatusContainer } from './statusContainer';
import { Store } from './store';
import { WarehouseContainer } from './warehouseContainer';
import { CommonAreaContainer } from './commonAreaContainer/commonAreaContainer';
import { StorageAreaContainer } from './storageAreaContainer/storageAreaContainer';

export default function() {
  const store = React.useMemo(() => new Store(), []);
  React.useEffect(() => {
    const oneHour = 60 * 60 * 1000;
    const timer = setInterval(() => {
      store.handleRefresh();
    }, oneHour);

    return () => clearInterval(timer);
  }, []);

  return (
    <ScreenContainer
      backgroundUrl={getStaticResourceUrl('pc/ts/egenie-cloud-report/moduleEfficiencyReportBg.png')}
      screenHeight={1080}
      screenWidth={1920}
    >
      <FullScreenContainer/>
      <WarehouseContainer store={store}/>
      <CommonAreaContainer store={store}/>
      <StorageAreaContainer store={store}/>
      <StatusContainer store={store}/>
      <ProcessContainer store={store}/>
    </ScreenContainer>
  );
}
