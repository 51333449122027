import React from 'react';
import styles from './commonAreaContainer.less';
import { Col, Row, Space, Tooltip, Typography } from 'antd';
import { thousandthSeparator, toFixed, RenderByCondition } from 'egenie-common';
import { observer } from 'mobx-react';
import type { CommonAreaItem } from '../types';
import classnames from 'classnames';
import { nanoid } from 'nanoid';
import type { Store } from '../store';

const ENUM_COMMON_AREA_STATUS = {
  easy: {
    value: 0,
    label: '闲',
    showDot: true,
    color: '#02C190',
    bgImg: require('../images/状态-闲.png'),
  },
  normal: {
    value: 1,
    label: '工作中',
    showDot: false,
    color: '#34b1fe',
    bgImg: require('../images/状态-正常.png'),
  },
  busy: {
    value: 2,
    label: '忙',
    showDot: true,
    color: '#fc4c7d',
    bgImg: require('../images/状态-忙.png'),
  },
};

interface CommonAreaViewItemProps {
  data: CommonAreaItem[];
  width: number;
  height: number;
  left: number;
  top: number;
  horizontalGap?: number;
  verticalGap?: number;
  className?: string;
  style?: React.CSSProperties;
}

const CommonAreaViewItem: React.FC<CommonAreaViewItemProps> = observer(({
  data,
  width,
  height,
  left,
  top,
  horizontalGap = 48,
  verticalGap = 24,
  className = '',
  style = {},
}) => {
  return (
    <div
      className={classnames(className, styles.commonAreaContainer)}
      style={{
        width,
        height,
        left,
        top,
        ...style,
      }}
    >
      <Row
        gutter={[
          horizontalGap,
          verticalGap,
        ]}
      >
        {
          data.map((item) => {
            const statusItem = Object.values(ENUM_COMMON_AREA_STATUS)
              .find((val) => val.value === item.status) || ENUM_COMMON_AREA_STATUS.normal;

            return (
              <Col
                key={nanoid()}
                span={6}
              >
                <Tooltip
                  arrowPointAtCenter
                  color="#014775"
                  overlayStyle={{ maxWidth: 500 }}
                  title={(
                    <Space
                      direction="vertical"
                      size={4}
                    >
                      <div className={styles.commonDescription}>
                        <Typography.Title
                          ellipsis
                          level={3}
                        >
                          {item.deviceAlias}
                        </Typography.Title>
                      </div>
                      <div className={styles.commonDescription}>
                        <Typography.Title
                          ellipsis
                          level={3}
                        >
                          已完成/总量(件): &nbsp;
                          {thousandthSeparator(item.finishNum)}
                          /
                          {thousandthSeparator(item.totalNum)}
                        </Typography.Title>
                      </div>
                      <div className={styles.commonDescription}>
                        <Typography.Text ellipsis>
                          时效:&nbsp;
                          {thousandthSeparator(item.aging)}
                          件/小时
                        </Typography.Text>
                      </div>
                      <div className={styles.commonDescription}>
                        <Typography.Text ellipsis>
                          饱和时效:&nbsp;
                          {thousandthSeparator(item.saturationAging)}
                          件/小时
                        </Typography.Text>
                      </div>
                      <div className={styles.commonDescription}>
                        <Typography.Text ellipsis>
                          饱和度:&nbsp;
                          {toFixed(item.saturationRatio, 2)}
                          %
                        </Typography.Text>
                      </div>
                      <RenderByCondition show={statusItem.showDot}>
                        <span
                          className={styles.statusContainer}
                          style={{ backgroundColor: statusItem.color }}
                        >
                          {statusItem.label}
                        </span>
                      </RenderByCondition>
                    </Space>
                  )}
                >
                  <div
                    className={styles.commonItem}
                    style={{ backgroundImage: `url(${statusItem.bgImg})` }}
                  >
                    <RenderByCondition show={statusItem.showDot}>
                      <span
                        style={{
                          width: 8,
                          height: 8,
                          marginRight: 2,
                          borderRadius: '100%',
                          backgroundColor: statusItem.color,
                        }}
                      />
                    </RenderByCondition>
                    <Typography.Text ellipsis>
                      {item.deviceAlias}
                    </Typography.Text>
                  </div>
                </Tooltip>
              </Col>
            );
          })
        }
      </Row>
    </div>
  );
});

export const CommonAreaContainer: React.FC<{ store: Store; }> = observer(({
  store: {
    arrivalData,
    deliverData,
    expressCollectionData,
    secondarySortingData,
    plusServiceData,
    refundVendorData,
    afterSaleData,
  },
}) => {
  return (
    <>
      <CommonAreaViewItem
        data={arrivalData}
        height={160}
        left={64}
        top={178}
        width={432}
      />
      <CommonAreaViewItem
        data={deliverData}
        height={252}
        left={64}
        top={475}
        width={432}
      />
      <CommonAreaViewItem
        data={expressCollectionData}
        height={68}
        left={64}
        top={920}
        width={432}
      />
      <CommonAreaViewItem
        data={secondarySortingData}
        height={68}
        left={570}
        top={230}
        width={432}
      />
      <CommonAreaViewItem
        data={plusServiceData}
        height={284}
        horizontalGap={20}
        left={1120}
        top={230}
        verticalGap={40}
        width={340}
      />
      <CommonAreaViewItem
        data={refundVendorData}
        height={68}
        horizontalGap={32}
        left={1528}
        top={220}
        width={366}
      />
      <CommonAreaViewItem
        data={afterSaleData}
        height={68}
        horizontalGap={32}
        left={1528}
        top={452}
        width={366}
      />
    </>
  );
});

