import React from 'react';

export enum ADAPTATION {
  width = 'width',
  height = 'height',
  isometric = 'isometric',
  none = 'none'
}

export interface ScreenContainerProps {
  className?: string;
  style?: React.CSSProperties;
  adaptation?: ADAPTATION;
  screenWidth: number;
  screenHeight: number;
  backgroundUrl: string;
}

export const ScreenContainer: React.FC<ScreenContainerProps> = ({
  className,
  style,
  children,
  screenWidth,
  screenHeight,
  backgroundUrl,
  adaptation = ADAPTATION.isometric,
}) => {
  const divRef = React.useRef<HTMLDivElement>();
  const calculateScale = React.useCallback(() => {
    let x: number;
    let y: number;
    switch (adaptation) {
      case ADAPTATION.width:
        x = 1;
        y = document.documentElement.clientHeight / screenHeight;
        break;
      case ADAPTATION.height:
        x = document.documentElement.clientWidth / screenWidth;
        y = 1;
        break;
      case ADAPTATION.isometric:
        x = document.documentElement.clientWidth / screenWidth;
        y = document.documentElement.clientHeight / screenHeight;
        break;
      case ADAPTATION.none:
      default:
        x = 1;
        y = 1;
    }

    if (divRef.current) {
      divRef.current.style.transformOrigin = '0% 0%';
      divRef.current.style.transform = `scaleX(${x}) scaleY(${y})`;
    }
  }, []);

  React.useEffect(() => {
    calculateScale();
    window.addEventListener('resize', calculateScale);
    return () => window.removeEventListener('resize', calculateScale);
  }, []);

  return (
    <div
      className={className}
      ref={divRef}
      style={{
        position: 'relative',
        width: screenWidth,
        height: screenHeight,
        backgroundRepeat: 'no-repeat',
        backgroundImage: `url(${backgroundUrl})`,
        ...style,
      }}
    >
      {children}
    </div>
  );
};
