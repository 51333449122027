import React from 'react';
import styles from './index.less';
import { useFullscreen } from 'ahooks';

export const FullScreenContainer: React.FC = function() {
  const [
    isFullscreen,
    { toggleFullscreen },
  ] = useFullscreen(() => document.documentElement);

  return (
    <img
      alt=""
      className={styles.fullScreenContainer}
      height={32}
      onClick={toggleFullscreen}
      src={isFullscreen ? require('./images/退出全屏.png') : require('./images/进入全屏.png')}
      width={32}
    />
  );
};
