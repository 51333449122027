import { Typography } from 'antd';
import { thousandthSeparator } from 'egenie-common';
import { observer } from 'mobx-react';
import React from 'react';
import type { Store } from './store';

export const ProcessContainer: React.FC<{ store: Store; }> = observer(({ store: { circulationData }}) => {
  const data: Array<{ value: string; left: number; top: number; color: string; }> = [
    {
      value: `到货${thousandthSeparator(circulationData.arrivalNum)}件`,
      left: 1191,
      top: 715,
      color: '#30B0ff',
    },
    {
      value: `直发${thousandthSeparator(circulationData.deliveryStraightNum)}件`,
      left: 1191,
      top: 805,
      color: '#30B0ff',
    },
    {
      value: `揽收${thousandthSeparator(circulationData.collectNum)}件`,
      left: 1191,
      top: 893,
      color: '#30B0ff',
    },
    {
      value: `出库${thousandthSeparator(circulationData.outStockNum)}件`,
      left: 1191,
      top: 987,
      color: '#30B0ff',
    },
    {
      value: `增值服务${thousandthSeparator(circulationData.addValueNum)}件`,
      left: 1436,
      top: 703,
      color: '#30B0ff',
    },
    {
      value: `发货${thousandthSeparator(circulationData.deliveryWaveNum)}件`,
      left: 1355,
      top: 748,
      color: '#30B0ff',
    },
    {
      value: `多件波次${thousandthSeparator(circulationData.multiWaveNum)}件`,
      left: 1395,
      top: 819,
      color: '#30B0ff',
    },
    {
      value: `上架${thousandthSeparator(circulationData.putOnNum)}件`,
      left: 1337,
      top: 893,
      color: '#30B0ff',
    },
    {
      value: `单件波次${thousandthSeparator(circulationData.singleWaveNum)}件`,
      left: 1337,
      top: 952,
      color: '#30B0ff',
    },
    {
      value: `退货${thousandthSeparator(circulationData.refundNum)}件`,
      left: 1625,
      top: 864,
      color: '#f14b7a',
    },
    {
      value: `出库${thousandthSeparator(circulationData.refundOutStockNum)}件`,
      left: 1800,
      top: 882,
      color: '#f14b7a',
    },
    {
      value: `上架${thousandthSeparator(circulationData.refundPutOnNum)}件`,
      left: 1625,
      top: 952,
      color: '#f14b7a',
    },
    {
      value: `售后快递${thousandthSeparator(circulationData.afterSaleNum)}包`,
      left: 1784,
      top: 986,
      color: '#f14b7a',
    },
    {
      value: '到货区',
      left: 1160,
      top: 759,
      color: '#fff',
    },
    {
      value: '发货区',
      left: 1160,
      top: 846,
      color: '#fff',
    },
    {
      value: '快递揽收区',
      left: 1149,
      top: 934,
      color: '#fff',
    },
    {
      value: '二次分拣区',
      left: 1446,
      top: 759,
      color: '#fff',
    },
    {
      value: '存储区',
      left: 1458,
      top: 876,
      color: '#fff',
    },
    {
      value: '增值服务区',
      left: 1735,
      top: 759,
      color: '#fff',
    },
    {
      value: '退供应商区',
      left: 1735,
      top: 846,
      color: '#fff',
    },
    {
      value: '售后区',
      left: 1747,
      top: 934,
      color: '#fff',
    },
  ];

  return (
    <>
      {
        data.map((item) => (
          <Typography.Text
            ellipsis
            key={`${item.left},${item.top}`}
            style={{
              position: 'absolute',
              left: item.left,
              top: item.top,
              lineHeight: '14px',
              color: item.color,
              fontWeight: 500,
              maxWidth: 150,
            }}
            title={item.value}
          >
            {item.value}
          </Typography.Text>
        ))
      }
    </>
  );
});
