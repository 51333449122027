import { Space, Tooltip } from 'antd';
import moment from 'moment';
import { observer } from 'mobx-react';
import React from 'react';
import styles from './index.less';
import type { Store } from './store';

export const StatusContainer: React.FC<{ store: Store; }> = observer(({ store: { lastUpdate }}) => {
  return (
    <>
      <div className={styles.globalStatusContainer}>
        <Space>
          <Tooltip
            arrowPointAtCenter
            placement="bottomLeft"
            title="忙: 时效超过饱和值的80%"
          >
            <img
              alt=""
              height={34}
              src={require('./images/状态-忙.png')}
              width={30}
            />
            &nbsp;
            忙
            &nbsp;
            <img
              alt=""
              height={12}
              src={require('./images/注释.png')}
              width={12}
            />
          </Tooltip>
          <Tooltip
            arrowPointAtCenter
            placement="bottomLeft"
            title="闲: 时效低于饱和值的20%"
          >
            <img
              alt=""
              height={34}
              src={require('./images/状态-闲.png')}
              width={30}
            />
            &nbsp;
            闲
            &nbsp;
            <img
              alt=""
              height={12}
              src={require('./images/注释.png')}
              width={12}
            />
          </Tooltip>
        </Space>
      </div>
      <div className={styles.storageStatusContainer}>
        <Space>
          <Tooltip
            arrowPointAtCenter
            placement="bottomLeft"
            title="满: "
          >
            <img
              alt=""
              height={24}
              src={require('./images/状态-满.png')}
              width={31}
            />
            &nbsp;
            满
            &nbsp;
            <img
              alt=""
              height={12}
              src={require('./images/注释.png')}
              width={12}
            />
          </Tooltip>
          <Tooltip
            arrowPointAtCenter
            placement="bottomLeft"
            title="已使用: "
          >
            <img
              alt=""
              height={24}
              src={require('./images/状态-已使用.png')}
              width={31}
            />
            &nbsp;
            已使用
            &nbsp;
            <img
              alt=""
              height={12}
              src={require('./images/注释.png')}
              width={12}
            />
          </Tooltip>
          <Tooltip
            arrowPointAtCenter
            placement="bottomLeft"
            title="空: "
          >
            <img
              alt=""
              height={24}
              src={require('./images/状态-空.png')}
              width={31}
            />
            &nbsp;
            空
            &nbsp;
            <img
              alt=""
              height={12}
              src={require('./images/注释.png')}
              width={12}
            />
          </Tooltip>
        </Space>
      </div>
      <div className={styles.lastUpdateContainer}>
        上次更新
        {moment(lastUpdate)
          .format('HH:mm')}
        &nbsp;&nbsp;
        60分钟更新一次
      </div>
    </>
  );
});
