import { observer } from 'mobx-react';
import React from 'react';
import styles from './index.less';
import type { Store } from './store';
import { Select } from 'antd';

export const WarehouseContainer: React.FC<{ store: Store; }> = observer(({
  store: {
    warehouse,
    warehouseData,
    handleWarehouseChange,
  },
}) => {
  return (
    <div className={styles.warehouseContainer}>
      <Select
        onChange={handleWarehouseChange}
        optionFilterProp="label"
        options={warehouseData}
        placeholder="请选择仓库"
        showSearch
        size="large"
        value={warehouse}
      />
    </div>
  );
});
